import React from "react";
import { PageProps } from "gatsby";
import Address from "../../components/address";
import { useTranslation } from "gatsby-plugin-react-i18next";

const ContactPage: React.FC<PageProps> = () => {
  const { t } = useTranslation("navigation");

  return (
    <main className="full-centered">
      <h1>{t("contact")}</h1>
      <Address />
      <button>Download vCard</button>
    </main>
  );
};

export default ContactPage;
